import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map, tap } from 'rxjs';

import { gql } from '../../__generated__/gql';
import { UpdateMeInput } from '../../__generated__/graphql';
import { InferItemType } from '../utils/cast';
import { extractErrors } from '../utils/errors';

export type Auth = InferItemType<ReturnType<typeof AuthenticationService.prototype.getMe>>;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  readonly autoRefresh$ = new BehaviorSubject<void>(undefined);
  private readonly apollo = inject(Apollo);

  private _user: Auth;

  getMe() {
    return this.apollo
      .query({
        query: gql(`
          query Me {
            me {
              id
              roles
              firstName
              lastName
              email
              avatar {
                id
                uri
              }
              defaultViews {
                columnOrdersView {
                  name
                  order
                }
                columnsView {
                  color
                  isHidden
                  name
                }
              createdAt
              createdBy
              data
              id
              isDefault
              isPublic
              name
              type
              }
            }
          }
        `),
        variables: {},
        fetchPolicy: 'no-cache',
      })
      .pipe(map(({ data }) => data.me));
  }

  updateMe(input: UpdateMeInput) {
    return this.apollo
      .mutate({
        mutation: gql(`
          mutation UpdateMe($input: UpdateMeInput!) {
            updateMe(input: $input) {
                user {
                  id
                }
              }
            }
      `),
        variables: { input },
      })
      .pipe(
        map(({ data }) => data!.updateMe.user!),
        extractErrors(),
        tap(() => this.autoRefresh$.next()),
      );
  }

  get currentUser() {
    return this._user;
  }

  set currentUser(user: Auth) {
    this._user = user;
  }
}
